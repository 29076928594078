import {
  ADD_ITEM,
  DEL_ITEM,
  UPDATE_ITEM,
  EMPTY_CART,
  ADD_MENU,
  DEL_MENU,
} from "./types";

export const AddItem = (info) => {
  // storeCart();
  return {
    type: ADD_ITEM,
    payload: info,
  };
};

export const DelItem = (id) => {
  return {
    type: DEL_ITEM,
    payload: id,
  };
};
export const UpdateItem = (info) => {
  // storeCart();
  return {
    type: UPDATE_ITEM,
    payload: info,
  };
};

export const AddMenu = (info) => {
  // storeCart();
  return {
    type: ADD_MENU,
    payload: info,
  };
};
export const DelMenu = () => {
  // storeCart();
  return {
    type: DEL_MENU,
  };
};
export const EmptyCart = () => {
  // storeCart();
  return {
    type: EMPTY_CART,
  };
};
