import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "../SVG/Logo";
import * as styles from "../../styles/sections/footer.module.css";

const Insta = ({ style }) => (
  <img style={style} src="/SVG/Insta.svg" alt="Instagram Logo" />
);
const Facebook = ({ style }) => (
  <img style={style} src="/SVG/Facebook.svg" alt="Facebook Logo" />
);

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      strapiSocialLinks {
        Facebook
        Instagram
      }
    }
  `);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <Link to="/">
            <Logo style={{ height: "10rem", width: "10rem" }} fill="#8a0d10" />
          </Link>
        </div>
        <ul className={styles.right}>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.strapiSocialLinks.Instagram}
            >
              <Insta style={{ height: "25px", width: "25px" }} />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.strapiSocialLinks.Facebook}
            >
              <Facebook style={{ height: "25px", width: "25px" }} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
