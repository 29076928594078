import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CloseSquare } from "./menu/Icons";
import { ToggleCart } from "../Redux/actions/ToggleCart";
import * as styles from "../styles/CartList.module.css";
import { Link } from "gatsby";
import { DelItem, DelMenu } from "../Redux/actions/CartActions";

const EmptyCart = ({ width, height, color }) => (
  <img
    alt=""
    style={{ width: width || "1rem", height: height || "1rem", color: color }}
    src="/SVG/Emptycart.svg"
  />
);

const MenuCard = ({ dispatch, toggle, cart, img }) => {
  return (
    <div className={styles.cartCard}>
      <Link style={{ flex: "1" }} to={`/orderbuild`} onClick={toggle}>
        <GatsbyImage
          className={styles.cartCardImg}
          style={{ borderRadius: ` 2rem 0 0 2rem` }}
          image={img}
          alt=""
        />
      </Link>
      <div className={styles.cartCardInfo}>
        <div
          className={styles.cartCardClose}
          onClick={dispatch}
          style={{ cursor: "pointer" }}
        >
          <CloseSquare style={{ width: `1.8rem`, fill: "red" }} />
        </div>
        <h6 style={{ color: "#d19851" }}>Custom Menu</h6>
        <p style={{fontSize:'1.8rem',fontFamily:'sans-serif,Arial'}}>Guests:{`${cart.MenuProducts.guests}`}</p>
        <p style={{fontSize:'1.8rem',fontFamily:'sans-serif,Arial'}}>Items:{`${cart.MenuProducts.totalItems}`}</p>
        <p style={{ color: "red",fontSize:'1.8rem',fontFamily:'sans-serif,Arial' }}>MRP: ₹{cart.MenuTotal.toFixed(2)} </p>
        {/* <p>Serves: {item.serves}</p> */}
      </div>
    </div>
  );
};

const ProductCard = ({ Products, Toggle, handleDelete }) => {
  return (
    <React.Fragment>
      {Products.map((item, index) => {
        // setTotal((state) => (state += item.price));
        return (
          <div key={item.id} className={styles.cartCard}>
            <Link style={{ flex: "1" }} onClick={Toggle} to={`/${item.slug}`}>
              <GatsbyImage
                className={styles.cartCardImg}
                style={{ borderRadius: ` 2rem 0 0 2rem` }}
                image={item.img}
                alt=""
              />
            </Link>
            <div className={styles.cartCardInfo}>
              <div
                className={styles.cartCardClose}
                onClick={() => handleDelete(item.id)}
                style={{ cursor: "pointer" }}
              >
                <CloseSquare style={{ width: `1.8rem`, fill: "red" }} />
              </div>
              <h6 style={{ color: "#d19851",fontSize:'2.2rem' }}>{item.name}</h6>
              <p style={{ color: "red",fontSize:'1.8rem',fontFamily:'sans-serif,Arial' }}>MRP: ₹{item.price.toFixed(2)} </p>
              <p style={{fontFamily:'sans-serif,Arial'}}>
                Net: {item.quantity}
                {item.unit}
              </p>
              {/* <p>Serves: {item.serves}</p> */}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

const EmptyCartContent = ({ toggle }) => {
  return (
    <React.Fragment>
      <h5 style={{ textAlign: "center", color: "#fff0ba", margin: "3rem 0" }}>
        No Items in cart.
      </h5>
      <EmptyCart width="8rem" height="8rem" />
      <Link onClick={toggle} to="/products">
        <button
          className="primary-btn"
          style={{
            cursor: "pointer",
            textAlign: "center",
            marginTop: "5rem",
          }}
        >
          Browse our products
        </button>
      </Link>
      <Link onClick={toggle} to="/orderbuild">
        <button
          className="primary-btn"
          style={{
            cursor: "pointer",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          Build your own menu
        </button>
      </Link>
    </React.Fragment>
  );
};

function CartList({ hideCheckout=false }) {
  console.log("REMDER Cartlist")

  const data = useStaticQuery(graphql`
    {
      strapiMenuBuildPage {
        menuCartImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 350)
          }
        }
      }
    }
  `);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const handleDelete = (id) => {
    dispatch(DelItem(id));
  };
  const CartContent = () => {
    if (cart.CartProducts.length || Object.keys(cart.MenuProducts).length > 0) {
      return (
        <React.Fragment>
          <React.Fragment>
            {cart.CartProducts.length > 0 && (
              <ProductCard
                Products={cart.CartProducts}
                Toggle={() => dispatch(ToggleCart())}
                handleDelete={handleDelete}
              />
            )}
            {
              <React.Fragment>
                {Object.keys(cart.MenuProducts).length > 0 && (
                  <MenuCard
                    cart={cart}
                    toggle={() => dispatch(ToggleCart())}
                    dispatch={() => dispatch(DelMenu())}
                    img={
                      data.strapiMenuBuildPage.menuCartImage.childImageSharp
                        .gatsbyImageData
                    }
                  />
                )}
              </React.Fragment>
            }
            {/* <div className={styles.cartTotal}>
              <p>GST ({GST}%)</p>
              <p>₹ {(total * GST) / 100}</p>
            </div> */}
            <div className={styles.cartTotal}>
              <p style={{fontSize:'2.8rem'}}>Total</p>
              <p style={{fontSize:'2.8rem',fontFamily:'sans-serif,Arial'}}>₹ {cart.CartTotal + cart.MenuTotal}</p>
            </div>
          </React.Fragment>

          <React.Fragment>
            {/* Hide the checkout option if asked to do so */}
            {!hideCheckout && (
              <Link
                onClick={() => dispatch(ToggleCart())}
                style={{ paddingBottom: "2rem" }}
                to="/checkout"
              >
                <button
                  className="primary-btn"
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  Continue to checkout
                </button>
              </Link>
            )}
          </React.Fragment>
        </React.Fragment>
      );
    } else {
      return (
        <EmptyCartContent
          toggle={() => !hideCheckout && dispatch(ToggleCart())}
          hideCheckout={hideCheckout}
        />
      );
    }
  };
  return (
    <div className={styles.container}>
      <CartContent />
    </div>
  );
}

export default React.memo(CartList);
