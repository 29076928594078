// extracted by mini-css-extract-plugin
export const container = "nav-module--container--3Oxfe";
export const collapseContent = "nav-module--collapseContent--24j9h";
export const hamburgerIcon = "nav-module--hamburgerIcon--13eDT";
export const navList = "nav-module--navList--2JgB0";
export const LogoContainer = "nav-module--LogoContainer--cWKIC";
export const hoverImage = "nav-module--hoverImage--Gbmzs";
export const cart = "nav-module--cart--2Wpmr";
export const Nav768 = "nav-module--Nav768--1pEw9";
export const cartHead = "nav-module--cartHead--AAWQp";
export const headText = "nav-module--headText--3Wawj";
export const CollapseContent = "nav-module--CollapseContent--3tE71";
export const logoContainer = "nav-module--logo-container--LOzNG";