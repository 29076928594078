/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useDispatch,useSelector } from "react-redux";
import { SetOperatingStatus } from "../Redux/actions/OperationActions";
// Redux

import Nav from "./Nav";
import Footer from "./sections/Footer";
import SEO from "./seo";

const Layout = ({ children, title,description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      strapiOperations {
        open
        close
        operating
        preparationTime
        MaxGuestsServed
        EarliestDeliveryBy
        LastestDeliveryBy
      }
    }
  `);
  const dispatch = useDispatch();
  //Check if data has already been set
  const set = useSelector(state=>state.operations.set)

  useEffect(() => {
    !set&&dispatch(SetOperatingStatus(data.strapiOperations));
  }, []);

 
  return (
    <React.Fragment>
      <SEO title={title} description={description} />
      <p
        className="nav-zindex"
        style={{
          position: "relative",
          color: "red",
          textAlign: "center",
          margin: 0,
          padding: `1.8rem 0`,
          background: "#e4e0d2",
        }}
        id="alert"
      >
        **We Currently Only deliver in Bangalore**
      </p>
      <Nav />
      {children}
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
