import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { CloseSquare } from "./menu/Icons";
import Text from "./SVG/TextDivider1";
import { useSpring, animated, config } from "react-spring";
import * as styles from "../styles/nav.module.css";
import Logo from "./SVG/Logo";
import { useSelector, useDispatch } from "react-redux";
import { ToggleCart } from "../Redux/actions/ToggleCart";
import CartList from "./CartList";

const CartIcon = ({ width, height }) => (
  <img
    alt="Cart"
    style={{ width: width || "1rem", height: height || "1rem" }}
    src="/SVG/Carticon.svg"
  />
);

export default function TestNav() {
  const dispatch = useDispatch();

  const ToggleState = useSelector((state) => state.togglecart.toggle);
  const [toggleDrop, setToggleDrop] = useState(false);

  const NavItems = ({ style, buttonStyle, className }) => {
    return (
      <ul className={`${styles.navList} ${className}`}>
        <li>
          <Link style={style || { color: "white" }} to="/gallery">
            <p>Gallery</p>
          </Link>
        </li>
        <li>
          <Link style={style || { color: "white" }} to="/products">
            <p>Products</p>
          </Link>
        </li>
        <li>
          <Link style={style || { color: "white" }} to="/orderbuild">
            <p>Custom Menu</p>
          </Link>
        </li>
        <li>
          <Link style={style || { color: "white" }} to="/contact">
            <p>Contact</p>
          </Link>
        </li>
        <li>
          <button
            onClick={() => {
              dispatch(ToggleCart());
            }}
            className="primary-btn"
            style={buttonStyle || { color: "black" }}
          >
            <CartIcon width="1.8rem" height="1.8rem" />
          </button>
        </li>
      </ul>
    );
  };
  // React Spring styling
  const dropdown = useSpring({
    transform: toggleDrop ? `translate(0,10rem)` : `translate(0,-30rem)`,
    from: {
      transform: `translate(0, -30rem)`,
    },
    config: config.gentle,
  });

  useEffect(() => {
    const handleResize = () => {
      if (toggleDrop && window.innerWidth >= 768) {
        setToggleDrop(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleDrop]);

  const cartAnimation = useSpring({
    transform: ToggleState ? "translate(0,0)" : "translate(101%,0)",
    from: {
      transform: "translate(101%,0)",
    },
    config: { mass: 1, tension: 120, friction: 16 },
  });

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0 }} className="dropdown-zindex">
        <animated.div
          style={{ ...dropdown }}
          className={styles.collapseContent}
        >
          <NavItems
            buttonStyle={{ padding: "1.3rem 3rem", margin: "1em 1em" }}
            style={{ fontSize: "1.5rem", color: "white", margin: "10px 0" }}
          />
        </animated.div>
      </div>

      <div className={`${styles.container} nav-zindex`}>
        <nav>
          <div className={styles.LogoContainer}>
            <Link to="/">
              <Logo style={{ height: "10rem", width: "10rem" }} />
            </Link>
          </div>
          <div
            onClick={() => setToggleDrop((state) => !state)}
            className={styles.hamburgerIcon}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <NavItems className={styles.Nav768} />
        </nav>
      </div>
      {ToggleState && (
        <div
          onClick={() => {
            dispatch(ToggleCart());
          }}
          className="outsideClickDiv"
        />
      )}

      <animated.div style={cartAnimation} className={styles.cart}>
        <div
          style={{
            opacity: ToggleState ? 1 : 0,
            height: `100%`,
            overflowY: `scroll`,
            padding: `4rem 1rem`,
            position: `relative`,
          }}
        >
          <div className={styles.cartHead}>
            <Text fill="#d19851" className={styles.headText}>
              Order Summary
            </Text>
            <div
              className={styles.hoverImage}
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(ToggleCart())}
            >
              <CloseSquare style={{ width: `2rem`, fill: "red" }} />
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CartList />
          </div>
        </div>
      </animated.div>
    </React.Fragment>
  );
}
